import { useState, useEffect } from "react";
import Navbar from "../../Shared/Navbar";
import ReceiverReconRequestsTable from "./ReceiverReconRequestsTable";

import useCancellablePromise from "../../../Api/cancelRequest";
import { getCall } from "../../../Api/axios";
import { useTheme } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
const columns = [
  { id: "id", label: "Id", minWidth: 180, align: "center" },
  { id: "bap_id", label: "BAP Id", minWidth: 180, align: "center" },
  {
    id: "received_orders_count",
    label: "Received Orders Count",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-US"),
    align: "center",
  },
  {
    id: "orders_verified_count",
    label: "Orders Verified Count",
    format: (value) => value.toLocaleString("en-US"),
    minWidth: 100,
    align: "center",
  },
  {
    id: "status",
    label: "Status",
    format: (value) => value.toLocaleString("en-US"),
    minWidth: 100,
    align: "center",
  },
  {
    id: "createdAt",
    label: "Created On",
    minWidth: 180,
    format: (value) => value.toLocaleString("en-US"),
    align: "center",
  },
  {
    id: "ttl",
    label: "TTL",
    minWidth: 100,
    format: formatTTL,
    align: "center",
  },
];
function convertTTLtoHours(ttl) {
  const regex = /P(\d+)D/;
  const match = ttl.match(regex);

  if (!match) {
    console.warn(
      "TTL format is inconsistent or incorrect. Using the original TTL:",
      ttl
    );
    return ttl;
  }
  const days = parseInt(match[1], 10);
  const hours = days * 24;
  return `${hours} hrs`;
}

// Define custom formatting function for "TTL (in Hrs)" column
function formatTTL(ttl) {
  try {
    const hours = convertTTLtoHours(ttl);
    return `${hours} hrs`;
  } catch (error) {
    console.error("Error converting TTL to hours:", error.message);
    return "Invalid TTL";
  }
}

export default function ReceiverReconRequests() {
  const theme = useTheme();
  const { cancellablePromise } = useCancellablePromise();
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(() => {
    const savedPage = sessionStorage.getItem("reconrequeststablepage");
    return savedPage !== null ? parseInt(savedPage, 10) : 0
  });
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    const savedRowsPerPage = sessionStorage.getItem("reconrequeststablerowsperpage");
    return savedRowsPerPage !== null ? parseInt(savedRowsPerPage, 10) : 25
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [user, setUser] = useState();
  const [columnList, setColumnList] = useState(columns);
  const [loading, setLoading] = useState(true);

  //   const getUser = async (id) => {
  //     const url = `/api/v1/users/${id}`;
  //     const res = await getCall(url);
  //     setUser(res[0]);
  //     return res[0];
  //   };

  //   useEffect(() => {
  //     const user_id = localStorage.getItem("user_id");
  //     getUser(user_id);
  //   }, []);

  const getReconRequests = () => {
    const url = `/api/v1/reconRequestsInfo?limit=${rowsPerPage}&offset=${
      page * rowsPerPage
    }`;
    getCall(url)
      .then((resp) => {
        console.log("Here is the response from the API");
        console.dir(resp, { depth: null });
        console.log("Here is the response data from the API");
        console.dir(resp.data, { depth: null });
        console.log("Here is the response data.data from the API");
        console.dir(resp.data.data, { depth: null });
        console.log("Here is the response count from the API");
        console.dir(resp.data.count, { depth: null });
        setOrders(resp.data);
        setTotalRecords(resp.count);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getReconRequests();
  }, [page, rowsPerPage]);

  //   useEffect(() => {
  //     if(user && user?.role?.name === "Organization Admin"){
  //       const data = columns.filter((item) => item.id !== "provider_name")
  //       setColumnList(data);
  //     }
  //   }, [user]);

  const handlePageChange = (val) => {
    setPage(val);
    setLoading(true);
    sessionStorage.setItem("reconrequeststablepage", val);
  }

  const handleRowsPerPageChange = (val) => {
    setRowsPerPage(val);
    sessionStorage.setItem("reconrequeststablerowsperpage", val);
  }

  const loadingView = (
    <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "#fff",
      }}
    >
      <CircularProgress />
    </div>
  );

  return (
    <div className="container mx-auto my-8 px-2 md:px-8">
      {loading ? loadingView : (
        <>
          <div className="mb-4 flex flex-row justify-between items-center">
            <label
              style={{ color: theme.palette.text.primary }}
              className="font-semibold text-2xl">
              Receiver Recon Requests
            </label>
          </div>
          <ReceiverReconRequestsTable
            columns={columnList}
            data={orders}
            totalRecords={totalRecords}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
        </>
      )}
    </div>
  );
}
