//! This file is not being used 
import React, { useState, useEffect } from "react";
import { getCall } from "../../../Api/axios";

const Admin = () => {
  const [usersData, setUsersData] = useState([]);
  const [organizationsData, setOrganizationsData] = useState([]);

  useEffect(() => {
    // Fetch users data
    const fetchUsersData = async () => {
      try {
        const urlUsers = "/api/v1/users";
        const resUsers = await getCall(urlUsers);
        console.log(resUsers);
        setUsersData(resUsers);
      } catch (error) {
        console.error("Error fetching users data:", error);
      }
    };

    // Fetch organizations data
    const fetchOrganizationsData = async () => {
      try {
        const urlOrganization = "/api/v1/organizations";
        const resOrganizations = await getCall(urlOrganization);
        console.log(resOrganizations);
        setOrganizationsData(resOrganizations);
      } catch (error) {
        console.error("Error fetching organizations data:", error);
      }
    };

    // Fetch data when the component mounts
    fetchUsersData();
    fetchOrganizationsData();
  }, []);

  return (
    <div>
      <h1>Users Data</h1>
      <ul>
        {usersData.map((user) => (
          <li key={user.id}>{user.name}</li>
          // Add more details based on your user data structure
        ))}
      </ul>

      <h1>Organizations Data</h1>
      <ul>
        {organizationsData.map((organization) => (
          <li key={organization.id}>{organization.name}</li>
          // Add more details based on your organization data structure
        ))}
      </ul>
    </div>
  );
};

export default Admin;
