import React from "react";
import "./tnc.css";

const TermsAndConditions = () => {
    const terms = [
        {
            id: "tnc",
            label: "Terms and Conditions",
            listItems: (
                <div class="tnc-container py-1 flex flex-col">
                    <h2>Terms and Conditions</h2>
                    <h3 style="text-align:justify;">Introduction</h3>
                    <ol>
                        <li>Aurika Tech Private Limited (ATPL), hereinafter referred to as "Golden" or the "Company", operates an online seller platform (the "Platform"), where registered sellers (hereinafter referred to as the “Seller”) can offer products to customers and buyers through the applications or platforms of other network participants on the Open Network for Digital Commerce (ONDC) participating as buyer apps.</li>
                        <ol>
                            <li>The Platform includes the website at www.aurikatech.com (“Website”) its subdomains and the Seller Dashboard.</li>
                        </ol>
                        <li>The Company's role is limited to managing the Platform and related services to facilitate transactions between Sellers and Buyer Apps which include order management and logistics services such as product delivery.</li>
                        <li>This document sets forth the terms and conditions (the "Agreement") that the Seller must accept before accessing the Platform.</li>
                        <ol>
                            <li>This Agreement constitutes a legally binding contract between the Seller and Golden, effective from the date the Platform is used or the date these terms are updated, whichever is later.</li>
                            <li>By registering, signing in, or even just browsing the Platform, the Seller irrevocably accepts this Agreement and signifies their absolute and unconditional consent to all provisions contained in the agreement.</li>
                        </ol>
                    </ol>
                    <h3 style="text-align:justify;">Amendment to the Terms</h3>
                    <ol>
                        <li>The Seller can review the latest version of the terms and conditions at any time on this page.</li>
                        <li>The Company reserves the right to modify, change, amend, or update any part of the Agreement periodically and such amended provisions of the Agreement shall be effective immediately upon being posted on the Platform.</li>
                        <ol>
                            <li>It is the Seller’s responsibility to periodically check this page for any changes.</li>
                            <li>By continuing to use or access the Platform after changes are posted, the Seller signifies their acceptance of these updated terms.</li>
                            <li>If the Seller does not agree to any of the terms in the Agreement, they must stop using the platform immediately and notify the Company accordingly.</li>
                        </ol>
                    </ol>
                    <h3 style="text-align:justify;">Eligibility</h3>
                    <ol>
                        <li>The Platform and associated services are exclusively available to individuals and companies capable of forming legally binding contracts under the Indian Contract Act, 1872.</li>
                        <ol>
                            <li>Without limiting the foregoing, the Platform and services are not available for minors (individuals under the age of 18), un-discharged insolvents, etc.</li>
                            <li>Business entities, including sole proprietorship firms, companies, and partnerships that are capable of forming legally binding contracts under the Indian Contract Act, 1872, are permitted to access the Platform.</li>
                        </ol>
                        <li>Golden tries to verify the accuracy of the information that the Seller provides during registration on www.aurikatech.com. If the Company has any reason to believe that such information is incorrect, it reserves the right to revoke access to the Platform and services under this Agreement to the Seller.</li>
                    </ol>
                    <h3 style="text-align:justify;">Account Registration</h3>
                    <ol>
                        <li>The Company does not allow the Seller to access or view the contents of the Platform without registration on the Platform. To be eligible to sell products through the Platform, the Seller must create an account on the Platform and provide all requested information.</li>
                        <ol>
                            <li>The Seller agrees to provide accurate, current and complete information during the registration process, and to update such information to keep it accurate, current and complete.</li>
                            <li>The Company reserves the right to reject the account application, and to suspend or terminate the account or access to the Platform, as deemed appropriate by the Company.</li>
                        </ol>
                        <li>The Seller is required to enter a valid phone number when registering on the Platform to help the Company for verifications.</li>
                        <ol>
                            <li>By registering their phone number with the Company, the Seller consents to be contacted by the Company via phone calls, or SMS messages.</li>
                            <li>It is the responsibility of the Seller to provide the correct mobile number so that the Company can communicate with the Seller via SMS.</li>
                            <li>The Seller understands and agrees that if the Company sends an SMS but the Seller does not receive it because the Seller’s mobile number is incorrect or out of date or blocked by the Seller’s service provider, or the Seller is otherwise unable to receive SMS, the Company shall be deemed to have provided the communication to the Seller effectively.</li>
                        </ol>
                        <li>Notwithstanding anything to the contrary herein, the Seller acknowledges that they shall have no ownership or other property interest in their account. Furthermore, the Seller understands that all rights in and to their account are and shall always be owned by the Company.</li>
                        <ol>
                            <li>However, any and all activities undertaken by the Seller under their account shall be the sole responsibility of the Seller, and the Company shall not be liable for such activity in any manner.</li>
                        </ol>
                    </ol>
                    <h3 style="text-align:justify;">Accuracy, Completeness, and Intellectual Property of Information</h3>
                    <ol>
                        <li>The Seller is required to ensure that all products they submit for listing on the Platform comply with applicable laws and regulations, such as the Legal Metrology Rules.</li>
                        <ol>
                            <li>The Seller shall be solely responsible for the accuracy and completeness of the product listings.</li>
                        </ol>
                        <li>
                            <span style="color:rgb(33, 37, 41);">The Seller represents and warrants that they own or possess the necessary licenses, rights, consents and permissions to use and authorize the use of any content or materials they provide for use on the Platform.</span>
                        </li>
                        <ol>
                            <li>
                                <span style="color:rgb(33, 37, 41);">The Company shall not be held responsible in the event the Seller violates any intellectual property rights of other Parties and the Seller shall alone be responsible for such violations.</span>
                            </li>
                        </ol>
                        <li>The Seller understands, agrees and acknowledges that the Company will conduct a review of the product catalog they submit for listing.</li>
                        <ol>
                            <li>The Seller grants the Company full rights to approve the product listing as it is, to reject the product listing, or to make any changes to the submitted product information (particularly product titles and descriptions) as it deems fit.</li>
                            <li>The Seller agrees to fully accept all such changes made by the Company.</li>
                            <li>
                                <span style="color:rgb(33, 37, 41);">The Company reserves the right, but is not obligated, to monitor or edit or remove any content.</span>
                            </li>
                        </ol>
                        <li>The Seller shall ensure that the products they submit for listing are in stock and available for sale. If any product listed by them becomes out of stock, it is the Seller’s responsibility to promptly update the listing on the Platform by adjusting the available count to reflect this change.</li>
                    </ol>
                    <h3 style="text-align:justify;">Delivery and Returns</h3>
                    <ol>
                        <li>The Seller understands, agrees and acknowledges the Company will facilitate services including delivery and reverse pickups via a logistics service provider engaged by the Company for delivering the products between the Seller and the customer as required.</li>
                        <li>The Seller shall be responsible for packing the products to the customers in a timely manner to allow the Company to facilitate the timely delivery of the products.</li>
                        <li>The Seller shall ensure that the products are packaged in a safe and secure manner to prevent any damage. The Seller shall also take photos and videos for documentation purposes before handing over the shipment to the delivery agent as per the Company’s policies and procedures.</li>
                        <li>The Seller understands, agrees and acknowledges that the Company will be merely procuring the insurance from the logistics service provider. In case of any circumstances warranting an insurance claim from the insurer, the Seller agrees to cooperate with the Company until the insurance claim is processed.</li>
                        <li>The Seller understands, agrees and acknowledges that the fees charged by the logistics service provider will be passed on as an additional line item in the total amount quoted to the customer.</li>
                        <li>
                            <strong>Return and RTO</strong>:</li>
                        <ol>
                            <li>The Company reserves the right to return to the Seller any undelivered products that are not accepted by the customer for any reason whatsoever at the location(s) specified by the customer.</li>
                            <li>The Company reserves the right to apply the RTO (return to origin) charges, which will be the same as the agreed shipping rates.</li>
                            <li>The Seller agrees that the returns will be processed via the logistics service provider for all products not accepted by the customer for any reason whatsoever. The Seller will ensure that such products are accepted at the location(s) specified by them.</li>
                        </ol>
                        <li>
                            <strong>Reverse Pickups</strong>:</li>
                        <ol>
                            <li>“Reverse Pickup” refers to the collection of the products by logistics providers from the customer’s address and the delivery of these products to the Seller's location.</li>
                            <li>In case a reverse pick up is initiated by the Seller through the Company, the Seller agrees to bear all the charges related to the reverse pickup as provided by the logistics service provider.</li>
                        </ol>
                        <li>
                            <strong>Said To Contain Basis &amp; Inspection</strong>:&nbsp;</li>
                        <ol>
                            <li>The Parties understand that all Products by the Company or its logistics provider are on a “Said To Contain Basis”, i.e., the Company or its logistics providers are not obligated, nor expected, to verify the description and contents of the products as declared by the Seller.</li>
                            <ol>
                                <li>The Seller shall undertake and ensure to make a proper, true, fair, correct, and factual declaration regarding the description and value of Products.</li>
                            </ol>
                            <li>Further, the logistics provider is not responsible in any way whatsoever for the usability of the product.</li>
                        </ol>
                        <li>
                            <strong>Liability</strong>:&nbsp;</li>
                        <ol>
                            <li>Notwithstanding any contrary provisions in this Agreement, the maximum liability for any shipment processed through the Platform is limited to the compensation that the logistics provider and/or the insurer offers to the Company in event of a claim by the Seller, provided such claim is raised by the Seller within one (1) month from the date of damage or loss or theft. Any claims by the Seller should be submitted along with the copy of the signed shipping manifest.</li>
                        </ol>
                    </ol>
                    <h3 style="text-align:justify;">Financial Terms</h3>
                    <ol>
                        <li>The Seller understands, agrees and acknowledges that the fees and commissions associated with the sale of its products and/or services on the Platform, and the shipping costs will be added to the product’s price quoted by the Seller, and will then be sent to the buyer app and the customer.</li>
                        <li>The Company will remit payment to the Seller for sales of its products on the Platform, after deducting any applicable fees and commissions within T+7 days of the return window of the product(s) closing.</li>
                    </ol>
                    <h3 style="text-align:justify;">Tax Compliance and Legal Obligations</h3>
                    <ol>
                        <li>The Seller shall be solely responsible for all taxes, duties, and other levies applicable on the sale of their products. Any legal issues concerning tax payments are the sole liability of the Seller.</li>
                        <ol>
                            <li>The Company holds no responsibility for the legal correctness or validity of the levy of such taxes.</li>
                        </ol>
                        <li>The Seller authorizes the Company to generate invoices on their behalf, including creation of a Dynamic QR code on the invoice, to send to the customer.</li>
                        <li>The Seller shall maintain accurate and complete records of all transactions and sales made through the Platform. They shall provide such records to the Company upon request.</li>
                        <li>The Seller must not engage in any fraudulent or deceptive practices while using the Platform. They shall not misrepresent the quality, nature, or authenticity of the products listed by them on the Platform.</li>
                    </ol>
                    <h3 style="text-align:justify;">Obligations and Compliance as ONDC Network Participant</h3>
                    <ol>
                        <li>Golden, as a designated participant within the ONDC network, bears a solemn duty to comply with all prevailing ONDC regulations, rules, and directives.</li>
                        <li>The Seller, unequivocally consents to comply with the regulations and directives issued by ONDC from time to time. These directives could be related to catalog quality, order fulfillment, order delivery, order cancellation or customer grievances.</li>
                        <li>The Seller also authorizes the Company to provide ONDC and its associated entities with access to information and content, in accordance with the stipulated guidelines.</li>
                        <li>As an ONDC Network Participant, Golden will adjust, alter, and disseminate data in line with the standardized format prescribed by the ONDC protocol.</li>
                        <li>The Seller authorizes the Company to act on their behalf for the following steps limited to transmitting the offer from the Seller, receiving the order confirmation from the buyer app, relaying the request of a purchase order to the Seller, confirming the purchase order with the buyer app and managing other communication with the buyer app:</li>
                        <ol>
                            <li>Upon the Seller’s confirmation of their product offering and the terms and conditions of the offering, the Company will relay the offer to the buyer app through ONDC’s gateway.</li>
                            <li>When the buyer app places a purchase order with the Seller through the Company, the Company will forward the purchase order to the Seller for confirmation.</li>
                            <li>Upon the Seller's confirmation, the Company will confirm the Order on behalf of the Seller.</li>
                        </ol>
                    </ol>
                    <h3 style="text-align:justify;">Customer Service.</h3>
                    <ol>
                        <li>The Seller agrees to provide prompt and courteous customer service to customers who purchase their products through the Platform.</li>
                        <li>The Seller commits to promptly responding to any customer inquiries, complaints or disputes, and to work with the Company to resolve any such issues in a timely and satisfactory manner.</li>
                    </ol>
                    <h3 style="text-align:justify;">Termination</h3>
                    <ol>
                        <li>Either party may terminate this Agreement at any time, with or without cause, by providing written notice to the other party.</li>
                        <li>Upon termination of this Agreement, the Seller shall immediately cease using the Platform and remove all listings of its products from the Platform.</li>
                        <li>Termination of this Agreement will not affect the obligation of either party to settle any outstanding amounts owed to the other party in accordance with the terms stipulated within the agreement.</li>
                    </ol>
                    <h3 style="text-align:justify;">Indemnification</h3>
                    <ol>
                        <li>The Seller agrees to indemnify and hold harmless Aurika Tech Private Limited, its suppliers, and service providers, and their officers, directors, agents, and employees from any losses, costs, liabilities or expenses, including reasonable attorneys' fees, arising from the Seller’s breach of this Agreement, violation of any law, or infringement of third-party rights.</li>
                    </ol>
                    <h3 style="text-align:justify;">Limitation of Liability</h3>
                    <ol>
                        <li>In no event shall Aurika Tech Private Limited, its suppliers, or service providers, or their officers, directors, employees, contractors or agents be liable for any lost profits or any special, incidental or consequential damages arising out of or in connection with the Platform, the services, or this Agreement, regardless of the cause, including negligence.</li>
                        <li>Golden's, its suppliers', and service providers', cumulative liability, and the liability of their officers, directors, employees, contractors and agents to the Seller or any third parties in any circumstances shall be limited to payment received by Golden for that particular service or month.</li>
                        <ol>
                            <li>There are no warranties, express or implied, concerning the Platform or the services provided under this Agreement.</li>
                        </ol>
                    </ol>
                    <h3 style="text-align:justify;">Force Majeure</h3>
                    <ol>
                        <li>Neither party shall be liable for any delay or failure to perform its obligations under this Agreement due to circumstances or events beyond their reasonable control, including but not limited to acts of God, natural disasters, war, terrorism, government regulations, embargoes, strikes, labour disputes, or any other similar event (collectively referred to as 'Force Majeure Event').</li>
                        <li>However, the party affected by the Force Majeure Event shall make reasonable efforts to mitigate the impact of such event and resume its obligations under this Agreement as soon as practical.</li>
                    </ol>
                    <h3 style="text-align:justify;">Applicable Law</h3>
                    <ol>
                        <li>These terms under this agreement are governed and construed in accordance with the laws of the Union of India.</li>
                        <ol>
                            <li>The Seller hereby irrevocably consents to the exclusive jurisdiction and venue of courts in Andhra Pradesh, India, in all disputes arising out of or relating to the use of the Company's services or products.</li>
                        </ol>
                        <li>The use of the Platform and services is prohibited in any jurisdiction that does not give effect to all provisions of these terms and conditions, including without limitation this paragraph.</li>
                        <ol>
                            <li>The Seller agrees to indemnify and hold harmless the Company, subsidiaries, affiliates, officers and employees from any claims, demands, or damages, including reasonable attorneys' fees asserted by any third party due to or arising out of the Seller’s use of or conduct on the Company's services or products.</li>
                            <li>The Company reserves the right to modify or change the terms and conditions of the agreement without the Seller’s consent and the modified terms and conditions can be posted on this website.</li>
                            <li>Any dispute relating in any way to the visit to this site and use of the Platform shall be submitted to the exclusive jurisdiction of the courts at Andhra Pradesh, India.</li>
                        </ol>
                    </ol>
                </div>
            ),
        },
    ];

    return (
        <div>
            {terms.map((item) => (
                <div key={item.id}>
                    {item.listItems && (
                        <div>
                            <br />
                            {item.listItems}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default TermsAndConditions;
