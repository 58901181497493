export default [
  {
    id: "email",
    title: "Email",
    placeholder: "Enter Email Address",
    type: "input",
    email: true,
    required: true,
  },
  {
    id: "mobile",
    title: "Mobile Number",
    placeholder: "Enter Mobile Number",
    type: "input",
    mobile: true,
    maxLength: 10,
    required: true,
  },
  {
    id: "providerStoreName",
    title: "Business Name",
    placeholder: "Enter Business Name",
    type: "input",
    required: true,
  },
];
