export const countries = [
  { value: "AFG", key: "Afghanistan" },
  { value: "ALA", key: "Åland Islands" },
  { value: "ALB", key: "Albania" },
  { value: "DZA", key: "Algeria" },
  { value: "ASM", key: "American Samoa" },
  { value: "AND", key: "Andorra" },
  { value: "AGO", key: "Angola" },
  { value: "AIA", key: "Anguilla" },
  { value: "ATA", key: "Antarctica" },
  { value: "ATG", key: "Antigua and Barbuda" },
  { value: "ARG", key: "Argentina" },
  { value: "ARM", key: "Armenia" },
  { value: "ABW", key: "Aruba" },
  { value: "AUS", key: "Australia" },
  { value: "AUT", key: "Austria", eu: true },
  { value: "AZE", key: "Azerbaijan" },
  { value: "BHS", key: "Bahamas" },
  { value: "BHR", key: "Bahrain" },
  { value: "BGD", key: "Bangladesh" },
  { value: "BRB", key: "Barbados" },
  { value: "BLR", key: "Belarus" },
  { value: "BEL", key: "Belgium", eu: true },
  { value: "BLZ", key: "Belize" },
  { value: "BEN", key: "Benin" },
  { value: "BMU", key: "Bermuda" },
  { value: "BTN", key: "Bhutan" },
  { value: "BOL", key: "Bolivia, Plurinational State of" },
  { value: "BES", key: "Bonaire, Sint Eustatius and Saba" },
  { value: "BIH", key: "Bosnia and Herzegovina" },
  { value: "BWA", key: "Botswana" },
  { value: "BVT", key: "Bouvet Island" },
  { value: "BRA", key: "Brazil" },
  { value: "IOT", key: "British Indian Ocean Territory" },
  { value: "BRN", key: "Brunei Darussalam" },
  { value: "BGR", key: "Bulgaria", eu: true },
  { value: "BFA", key: "Burkina Faso" },
  { value: "BDI", key: "Burundi" },
  { value: "KHM", key: "Cambodia" },
  { value: "CMR", key: "Cameroon" },
  { value: "CAN", key: "Canada" },
  { value: "CPV", key: "Cape Verde" },
  { value: "CYM", key: "Cayman Islands" },
  { value: "CAF", key: "Central African Republic" },
  { value: "TCD", key: "Chad" },
  { value: "CHL", key: "Chile" },
  { value: "CHN", key: "China" },
  { value: "CXR", key: "Christmas Island" },
  { value: "CCK", key: "Cocos (Keeling) Islands" },
  { value: "COL", key: "Colombia" },
  { value: "COM", key: "Comoros" },
  { value: "COG", key: "Congo" },
  { value: "COD", key: "Congo, the Democratic Republic of the" },
  { value: "COK", key: "Cook Islands" },
  { value: "CRI", key: "Costa Rica" },
  { value: "CIV", key: "Côte d'Ivoire" },
  { value: "HRV", key: "Croatia", eu: true },
  { value: "CUB", key: "Cuba" },
  { value: "CUW", key: "Curaçao" },
  { value: "CYP", key: "Cyprus", eu: true },
  { value: "CZE", key: "Czech Republic", eu: true },
  { value: "DNK", key: "Denmark", eu: true },
  { value: "DJI", key: "Djibouti" },
  { value: "DMA", key: "Dominica" },
  { value: "DOM", key: "Dominican Republic" },
  { value: "ECU", key: "Ecuador" },
  { value: "EGY", key: "Egypt" },
  { value: "SLV", key: "El Salvador" },
  { value: "GNQ", key: "Equatorial Guinea" },
  { value: "ERI", key: "Eritrea" },
  { value: "EST", key: "Estonia", eu: true },
  { value: "ETH", key: "Ethiopia" },
  { value: "FLK", key: "Falkland Islands (Malvinas)" },
  { value: "FRO", key: "Faroe Islands" },
  { value: "FJI", key: "Fiji" },
  { value: "FIN", key: "Finland", eu: true },
  { value: "FRA", key: "France", eu: true },
  { value: "GUF", key: "French Guiana" },
  { value: "PYF", key: "French Polynesia" },
  { value: "ATF", key: "French Southern Territories" },
  { value: "GAB", key: "Gabon" },
  { value: "GMB", key: "Gambia" },
  { value: "GEO", key: "Georgia" },
  { value: "DEU", key: "Germany", eu: true },
  { value: "GHA", key: "Ghana" },
  { value: "GIB", key: "Gibraltar" },
  { value: "GRC", key: "Greece", eu: true },
  { value: "GRL", key: "Greenland" },
  { value: "GRD", key: "Grenada" },
  { value: "GLP", key: "Guadeloupe" },
  { value: "GUM", key: "Guam" },
  { value: "GTM", key: "Guatemala" },
  { value: "GGY", key: "Guernsey" },
  { value: "GIN", key: "Guinea" },
  { value: "GNB", key: "Guinea-Bissau" },
  { value: "GUY", key: "Guyana" },
  { value: "HTI", key: "Haiti" },
  { value: "HMD", key: "Heard Island and McDonald Islands" },
  { value: "VAT", key: "Holy See (Vatican City State)" },
  { value: "HND", key: "Honduras" },
  { value: "HKG", key: "Hong Kong" },
  { value: "HUN", key: "Hungary", eu: true },
  { value: "ISL", key: "Iceland" },
  { value: "IND", key: "India" },
  { value: "keN", key: "Indonesia" },
  { value: "IRN", key: "Iran, Islamic Republic of" },
  { value: "IRQ", key: "Iraq" },
  { value: "IRL", key: "Ireland", eu: true },
  { value: "IMN", key: "Isle of Man" },
  { value: "ISR", key: "Israel" },
  { value: "ITA", key: "Italy", eu: true },
  { value: "JAM", key: "Jamaica" },
  { value: "JPN", key: "Japan" },
  { value: "JEY", key: "Jersey" },
  { value: "JOR", key: "Jordan" },
  { value: "KAZ", key: "Kazakhstan" },
  { value: "KEN", key: "Kenya" },
  { value: "KIR", key: "Kiribati" },
  { value: "PRK", key: "Korea, Democratic People's Republic of" },
  { value: "KOR", key: "Korea, Republic of" },
  { value: "KWT", key: "Kuwait" },
  { value: "KGZ", key: "Kyrgyzstan" },
  { value: "LAO", key: "Lao People's Democratic Republic" },
  { value: "LVA", key: "Latvia", eu: true },
  { value: "LBN", key: "Lebanon" },
  { value: "LSO", key: "Lesotho" },
  { value: "LBR", key: "Liberia" },
  { value: "LBY", key: "Libya" },
  { value: "LIE", key: "Liechtenstein" },
  { value: "LTU", key: "Lithuania", eu: true },
  { value: "LUX", key: "Luxembourg", eu: true },
  { value: "MAC", key: "Macao" },
  { value: "MKD", key: "Macedonia, the former Yugoslav Republic of" },
  { value: "MDG", key: "Madagascar" },
  { value: "MWI", key: "Malawi" },
  { value: "MYS", key: "Malaysia" },
  { value: "MDV", key: "Maldives" },
  { value: "MLI", key: "Mali" },
  { value: "MLT", key: "Malta", eu: true },
  { value: "MHL", key: "Marshall Islands" },
  { value: "MTQ", key: "Martinique" },
  { value: "MRT", key: "Mauritania" },
  { value: "MUS", key: "Mauritius" },
  { value: "MYT", key: "Mayotte" },
  { value: "MEX", key: "Mexico" },
  { value: "FSM", key: "Micronesia, Federated States of" },
  { value: "MDA", key: "Moldova, Republic of" },
  { value: "MCO", key: "Monaco" },
  { value: "MNG", key: "Mongolia" },
  { value: "MNE", key: "Montenegro" },
  { value: "MSR", key: "Montserrat" },
  { value: "MAR", key: "Morocco" },
  { value: "MOZ", key: "Mozambique" },
  { value: "MMR", key: "Myanmar" },
  { value: "NAM", key: "Namibia" },
  { value: "NRU", key: "Nauru" },
  { value: "NPL", key: "Nepal" },
  { value: "NLD", key: "Netherlands", eu: true },
  { value: "NCL", key: "New Caledonia" },
  { value: "NZL", key: "New Zealand" },
  { value: "NIC", key: "Nicaragua" },
  { value: "NER", key: "Niger" },
  { value: "NGA", key: "Nigeria" },
  { value: "NIU", key: "Niue" },
  { value: "NFK", key: "Norfolk Island" },
  { value: "MNP", key: "Northern Mariana Islands" },
  { value: "NOR", key: "Norway" },
  { value: "OMN", key: "Oman" },
  { value: "PAK", key: "Pakistan" },
  { value: "PLW", key: "Palau" },
  { value: "PSE", key: "Palestinian Territory, Occupied" },
  { value: "PAN", key: "Panama" },
  { value: "PNG", key: "Papua New Guinea" },
  { value: "PRY", key: "Paraguay" },
  { value: "PER", key: "Peru" },
  { value: "PHL", key: "Philippines" },
  { value: "PCN", key: "Pitcairn" },
  { value: "POL", key: "Poland", eu: true },
  { value: "PRT", key: "Portugal", eu: true },
  { value: "PRI", key: "Puerto Rico" },
  { value: "QAT", key: "Qatar" },
  { value: "REU", key: "Réunion" },
  { value: "ROU", key: "Romania", eu: true },
  { value: "RUS", key: "Russian Federation" },
  { value: "RWA", key: "Rwanda" },
  { value: "BLM", key: "Saint Barthélemy" },
  { value: "SHN", key: "Saint Helena, Ascension and Tristan da Cunha" },
  { value: "KNA", key: "Saint Kitts and Nevis" },
  { value: "LCA", key: "Saint Lucia" },
  { value: "MAF", key: "Saint Martin (French part)" },
  { value: "SPM", key: "Saint Pierre and Miquelon" },
  { value: "VCT", key: "Saint Vincent and the Grenadines" },
  { value: "WSM", key: "Samoa" },
  { value: "SMR", key: "San Marino" },
  { value: "STP", key: "Sao Tome and Principe" },
  { value: "SAU", key: "Saudi Arabia" },
  { value: "SEN", key: "Senegal" },
  { value: "SRB", key: "Serbia" },
  { value: "SYC", key: "Seychelles" },
  { value: "SLE", key: "Sierra Leone" },
  { value: "SGP", key: "Singapore" },
  { value: "SXM", key: "Sint Maarten (Dutch part)" },
  { value: "SVK", key: "Slovakia", eu: true },
  { value: "SVN", key: "Slovenia", eu: true },
  { value: "SLB", key: "Solomon Islands" },
  { value: "SOM", key: "Somalia" },
  { value: "ZAF", key: "South Africa" },
  { value: "SGS", key: "South Georgia and the South Sandwich Islands" },
  { value: "SSD", key: "South Sudan" },
  { value: "ESP", key: "Spain", eu: true },
  { value: "LKA", key: "Sri Lanka" },
  { value: "SDN", key: "Sudan" },
  { value: "SUR", key: "Surikey" },
  { value: "SJM", key: "Svalbard and Jan Mayen" },
  { value: "SWZ", key: "Swaziland" },
  { value: "SWE", key: "Sweden", eu: true },
  { value: "CHE", key: "Switzerland" },
  { value: "SYR", key: "Syrian Arab Republic" },
  { value: "TWN", key: "Taiwan, Province of China" },
  { value: "TJK", key: "Tajikistan" },
  { value: "TZA", key: "Tanzania, United Republic of" },
  { value: "THA", key: "Thailand" },
  { value: "TLS", key: "Timor-Leste" },
  { value: "TGO", key: "Togo" },
  { value: "TKL", key: "Tokelau" },
  { value: "TON", key: "Tonga" },
  { value: "TTO", key: "Trinkead and Tobago" },
  { value: "TUN", key: "Tunisia" },
  { value: "TUR", key: "Turke" },
  { value: "TKM", key: "Turkmenistan" },
  { value: "TCA", key: "Turks and Caicos Islands" },
  { value: "TUV", key: "Tuvalu" },
  { value: "UGA", key: "Uganda" },
  { value: "UKR", key: "Ukraine" },
  { value: "ARE", key: "United Arab Emirates" },
  { value: "GBR", key: "United Kingdom", eu: true },
  { value: "USA", key: "United States" },
  { value: "UMI", key: "United States Minor Outlying Islands" },
  { value: "URY", key: "Uruguay" },
  { value: "UZB", key: "Uzbekistan" },
  { value: "VUT", key: "Vanuatu" },
  { value: "VEN", key: "Venezuela, Bolivarian Republic of" },
  { value: "VNM", key: "Viet Nam" },
  { value: "VGB", key: "Virgin Islands, British" },
  { value: "VIR", key: "Virgin Islands, U.S." },
  { value: "WLF", key: "Wallis and Futuna" },
  { value: "ESH", key: "Western Sahara" },
  { value: "YEM", key: "Yemen" },
  { value: "ZMB", key: "Zambia" },
  { value: "ZWE", key: "Zimbabwe" },
];
