import { useScrollTrigger, Zoom } from "@mui/material";
import { IconButton } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export const ScrollTop = () => {
  const trigger = useScrollTrigger({
    target: window,
    //uncomment below to return true for both up/down scroll direction
    // disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        className="fixed right-[7.5%] bottom-[7.5%] z-50"
      >
        <IconButton
          color="tertiary"
          size="small"
          sx={{
            border: "1px solid",
            borderColor: "secondary.main",
            backgroundColor: "primary.main",
            "&:hover": { backgroundColor: "secondary.main" },
          }}
        >
          <KeyboardArrowUpIcon sx={{ fontSize: 32 }} />
        </IconButton>
      </div>
    </Zoom>
  );
}
