import MyButton from "../../Shared/Button";
import VariantForm from "./VariantForm";
import { v4 as uuidv4 } from "uuid";
import { useTheme } from "@mui/material";

const AddVariants = ({
  variantFields,
  variantInitialValues,
  variantForms,
  setVariantForms,
  shouldValidate,
  variantFormsErrors,
  generalInfoValues,
  goldRate
}) => {
  const theme = useTheme();
  const addNewVariationForm = () => {
    setVariantForms([...variantForms, { ...variantInitialValues, formKey: uuidv4() }]);
  };

  console.log('variant forms data: ', variantForms)

  const handleOnVariantFormUpdate = (index, formValues) => {
    variantForms[index] = formValues;
    setVariantForms([...variantForms]);
  };

  const handleRemoveForm = (i) => {
    variantForms.splice(i, 1);
    setVariantForms([...variantForms]);
    variantFormsErrors.splice(i, 1);
  };

  const renderForms = () => {
    return variantForms.map((form, i) => {
      return (
        <VariantForm
          key={form.formKey}
          index={i}
          formData={form}
          fields={variantFields}
          onFormUpdate={handleOnVariantFormUpdate}
          shouldValidate={shouldValidate}
          formsErrors={variantFormsErrors}
          removeForm={handleRemoveForm}
          generalInfoValues={generalInfoValues}
          goldRate={goldRate}
        />
      );
    });
  };

  return (
    <>
      {renderForms()}
      <MyButton type="button" title="Add Variation" className="!text-lg !font-bold !tracking-wide !text-[#b47437] !underline" onClick={() => addNewVariationForm()}/>
    </>
  );
};

export default AddVariants;
