import React from "react";
// import styles from "../../../styles/auth/auth.module.scss";
import logo from "../../../Assets/Images/logo-aurika.png";
import { useTheme } from "@mui/material/styles";

export default function AuthActionCard(props) {
  const { action_form, navigation_link } = props;
  const theme = useTheme();
  return (
    <div className="mx-auto !p-5 h-screen min-vh-100 overflow-auto bg-[#f0f0f0]">
      <div className="h-full flex fex-row items-center justify-center">
      <div className="flex flex-col justify-center h-3/4 min-h-[28rem] w-full md:w-2/4 px-2.5 py-2.5 rounded-md shadow-xl outline outline-4 outline-offset-8"
      style={{backgroundColor: theme.palette.primary.main, outlineColor: theme.palette.secondary.main}}
      >
      <div
            // style={{ height: '20%' }}
            className="flex fex-row items-center justify-center h-24 overflow-hidden"
            style={{backgroundColor: theme.palette.primary.main}}
          >
            <img src={logo} alt="logo" style={{ height: "250px" }} />
          </div>
          <div
            // style={{ height: '70%' }}
            className="overflow-auto flex justify-center"
          >
            {action_form}
          </div>
          <div
            // style={{ height: '10%' }}
            className="flex fex-row items-center justify-center"
          >
            {navigation_link}
          </div>
        </div>
      </div>
    </div>
  );
}
