import { useState, useEffect } from "react";
import SettlementsTable from "./SettlementsTable";
import { getCall } from "../../../Api/axios";
import { useTheme } from '@mui/material/styles';
import { CircularProgress } from "@mui/material";

const columns = [
  { id: "provider_store", label: "Provider Store Name", minWidth: 180, align: "center" },
  { id: "orderId", label:"Order Id", minWidth:180, align:"center" },
  { id: "orderDate", label: "Order Date", minWidth: 100, align: "center" },
  { id: "orderStatus", label: "Order Status", minWidth: 100, align: "center" },
  { id: "buyerPrice", label: "Buyer Price", minWidth: 100, align: "center" },
  { id: "paymentType", label: "Payment Type", minWidth: 180, align: "center" },
  { id: "refundAmount", label: "Refund Amount", minWidth: 180, align: "center" },
  { id: "updatedAt", label: "Last Updated On", minWidth: 180, align: "center" },
  { id: "receiverReconStatus", label: "Receiver Recon Status", minWidth: 180, align: "center" },
  { id: "bapSettledAmount", label: "Buyer App Settled Amount", minWidth: 180, align: "center" },
  { id: "sellerTotalConsideration", label: "Seller's Total Consideration", minWidth: 180, align: "center" },
  { id: "tdsDeduction", label: "TDS Deduction", minWidth: 180, align: "center" },
  { id: "tcsDeduction", label: "TCS Deduction", minWidth: 180, align: "center" },
  { id: "sellerSettlementAmount", label: "Settlement Amount To Seller", minWidth: 180, align: "center" },
  { id: "sellerSettlementStatus", label: "Seller Settlement Status", minWidth: 180, align: "center" },
  { id: "action", label: "Action", minWidth: 180, align: "center" },
];

export default function Settlements() {
  const theme = useTheme();
  const [settlements, setSettlements] = useState([]);
  const [page, setPage] = useState(() => {
    const savedPage = sessionStorage.getItem("settlementstablepage");
    return savedPage !== null ? parseInt(savedPage, 10) : 0
  });
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    const savedRowsPerPage = sessionStorage.getItem("settlementstablerowsperpage");
    return savedRowsPerPage !== null ? parseInt(savedRowsPerPage, 10) : 25
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading,setIsLoading] = useState(false);

  //useEffect to set Table Data and Pagination
  useEffect(() => {
    setIsLoading(true);
    getSettlements();
  }, [page, rowsPerPage]);

  const getSettlements = () => {
    const settlementsUrl = `api/v1/settlements?limit=${rowsPerPage}&offset=${page*rowsPerPage}`;
    getCall(settlementsUrl)
    .then((res)=>{
      console.log("Settlements fetched: ");
      console.dir(res, {depth:null});
      setSettlements(res.data);
      setTotalRecords(res.count);
      setIsLoading(false);
    }).catch((error)=>{
      console.error(error.message)
      setIsLoading(false);
    })
  }

  const handleUpdateComplete = () => {
    getSettlements();
  }

  const handlePageChange = (val) => {
    setPage(val);
    setIsLoading(true);
    sessionStorage.setItem("settlementstablepage", val);
  }

  const handleRowsPerPageChange = (val) => {
    setRowsPerPage(val);
    sessionStorage.setItem("settlementstablerowsperpage", val);
  }

  return (
    <div className="container mx-auto my-8 px-2 md:px-8">
    {isLoading ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#fff' }}>
        <CircularProgress />
      </div>
    ) : (
      <>
        <div className="mb-4 flex flex-row justify-between items-center">
          <label style={{color: theme.palette.text.primary}} className="font-semibold text-2xl">Settlements</label>
        </div>
        <SettlementsTable
          columns={columns}
          data={settlements}
          totalRecords={totalRecords}
          page={page}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          onUpdateComplete={handleUpdateComplete}
        />
      </>
    )}
  </div>
  );
}
