export default [
  {
    id: "name",
    title: "Your Full Name",
    placeholder: "Enter Your Full Name",
    type: "input",
    required: true,
  },
  {
    id: "PAN",
    title: "Your PAN Card Number",
    placeholder: "Enter Your PAN Number",
    type: "input",
    maxLength: 10,
    required: true,
    isUperCase: true,
  },
  // {
  //   id: "PAN_proof",
  //   title: "Upload Your PAN Card",
  //   type: "upload",
  //   file_type: "pan_proof",
  //   required: true,
  //   fontColor: "#ffffff",
  // },
  {
    id: "aadhar",
    title: "Your Aadhar Card Number",
    placeholder: "Enter Your Aaadhar Number",
    type: "input",
    maxLength: 12,
    required: true,
    isUperCase: true,
  },
  {
    id: "aadhar_proof",
    title: "Upload Your Aadhar Card",
    type: "upload",
    file_type: "aadhar_proof",
    required: true,
    fontColor: "#ffffff",
  },
  // {
  //   id: "signature_image",
  //   title: "Upload Your Signature Image (used only to generate customer invoices)",
  //   type: "upload",
  //   file_type: "signature_image",
  //   required: true,
  //   fontColor: "#ffffff",
  // },
  // {
  //   id: "contactEmail",
  //   title: "Email",
  //   placeholder: "Enter Provider's Email Address",
  //   type: "input",
  //   email: true,
  //   required: true,
  // },
  // {
  //   id: "contactMobile",
  //   title: "Mobile Number",
  //   placeholder: "Enter Provider's Mobile Number",
  //   type: "input",
  //   mobile: true,
  //   maxLength: 10,
  //   required: true,
  // },
];
