import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { patchCall } from "../../../Api/axios";
import cogoToast from "cogo-toast";

export default function FormDialog({ open, closeDialog, inputData, onUpdateComplete }) {
  const [isLoading, setIsLoading] = useState(false);
  const updateSettlement = async(formJson) => {
    setIsLoading(true)
    const updateUrl = `/api/v1/settlements/updateSettlement?settlementId=${inputData.id}`;
    await patchCall(updateUrl, formJson)
    .then((res)=>{
        cogoToast.success("Settlement Updated Successfully!");
        setIsLoading(false);
        onUpdateComplete();
        closeDialog();
    })
    .catch((error)=>{
        cogoToast.error("Something went wrong, check console for more info!!!");
        console.error(error);
        setIsLoading(false);
        closeDialog();
    });
  }
  return (
    <Dialog
      open={open}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          updateSettlement(formJson);
        },
        sx: {
          width: {
            xs: '90vw', // Extra-small screens
            sm: '70vw', // Small screens
            md: '50vw', // Medium screens
            lg: '30vw', // Large screens
          }
        }    
      }}
    >
      <DialogTitle>Update Settlement</DialogTitle>
      <DialogContent>
        <InputLabel id="settled-amount">Buyer App Settled Amount</InputLabel>
        <TextField
          labelId="settled-amount"
          margin="dense"
          id="buyerSettledAmount"
          name="buyerSettledAmount"
          type="text"
          fullWidth
          variant="standard"
          defaultValue = {inputData.buyerSettledAmount}
          sx={{marginBottom:2}}
        />
        <InputLabel id="settlement-status">Seller Settlement Status</InputLabel>
        <Select
        labelId="settlement-status"
        id="sellerSettlementStatus"
        name="sellerSettlementStatus"
        defaultValue={inputData.sellerSettlementStatus}
        fullWidth
         >
            <MenuItem value={'yes'}>Yes</MenuItem>
            <MenuItem value={'no'}>No</MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="tertiary" disabled={isLoading}>Cancel</Button>
        <Button type="submit" variant="outlined" color="tertiary" disabled={isLoading}>{`${isLoading ? 'Updating...':'Update'}`}</Button>
      </DialogActions>
    </Dialog>
  );
}
