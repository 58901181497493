import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import RenderInput from "../../utils/RenderInput";
import {
  isValidBankAccountNumber,
  isValidIFSC,
  isNameValid,
  isEmailValid,
  isValidPAN,
  isPhoneNoValid,
  isValidAadhar,
  isValidGSTIN,
} from "../../utils/validations";
import { postCall } from "../../Api/axios";
import cogoToast from "cogo-toast";
import { useNavigate } from "react-router-dom";
import { containsOnlyNumbers } from "../../utils/formatting/string";
import useForm from "../../hooks/useForm";
import userFields from "./provider-user-fields-modified";
import kycDetailFields from "./provider-key-fields-modified";
import kycDocumentFields from "./provider-kyc-doc-fields-modified";
import bankDetailFields from "./provider-bank-details-fields";
// import {
//   loadCaptchaEnginge,
//   LoadCanvasTemplate,
//   validateCaptcha,
// } from "react-simple-captcha";
const tnc = [
  {
    id: "tnc1",
    label: "I agree to the terms and conditions.",
    listItems: (
      <div>
        <p>Please keep the below documents ready:</p>
        <br />
        <br />
        <ol>
          <li>1. GSTIN & GSTIN Certificate</li>
          <li>2. Bank Account Details & Cancelled Cheque/Passbook</li>
          <li>3. Owner’s PAN Number</li>
          <li>4. Owner’s Aadhar Card</li>
        </ol>
        <br />
        <br />

        <p>
          By clicking 'I Agree,' you agree to our{' '}
          <a href="/tnc" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>
            terms and conditions
          </a>,{" "}and{" "}
          <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>
            privacy policy
          </a>.
        </p>
      </div>
    ),
  },
];
const InviteProvider = () => {
  const handleChange = (id, value) => {

    if (id === "GSTN") {
      const uppercaseGSTN = value.toUpperCase();
      setFormValues((prevValues) => ({
        ...prevValues,
        [id]: uppercaseGSTN,
      }));
    }
  };
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const user = {
    email: "",
    mobile: "",
    providerStoreName: "",
    password: "",
  };

  const kycDetails = {
    GSTN: "",
    GST_proof: "",
  };

  const [tncDetails, setTncDetails] = useState({
    tnc: false,
  });

  const kycMedia = {
    name: "",
    PAN: "",
    aadhar: "",
    aadhar_proof: "",
    // captcha: "",
    enabled: false,
  };

  const bankDetails = {
    accHolderName: "",
    accNumber: "",
    bankName: "",
    branchName: "",
    IFSC: "",
    cancelledCheque: "",
  };

  const { formValues, setFormValues, errors, setErrors } = useForm({
    ...user,
    ...kycDetails,
    ...kycMedia,
    ...bankDetails,
  });
  const [formSubmitted, setFormSubmited] = useState(false);

  const handleContinue = () => {
    console.log("next Step");
    setStep(step + 1);
    setFormSubmited(false);
  };
  const tncFields = () => {
    return (
      <div>
        {tnc.map((item) => (
          <div key={item.id}>
            <RenderInput
              item={item}
              state={tncDetails}
              stateHandler={setTncDetails}
            />
            {item.listItems && (
              <div>
                <br />
                {item.listItems}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };


  // useEffect(() => {
  //   if (step === 5) {
  //     console.log("alert");
  //     loadCaptchaEnginge(6);
  //   }
  // }, [step]);

  const sendInvite = async () => {
    setFormSubmited(true);
    try {
      const data = {
        user: {
          name: formValues.name,
          email: formValues.email,
          mobile: formValues.mobile,
          password: formValues.password,
          documentApprovalStatus: formValues.documentApprovalStatus,
        },
        providerDetails: {
          name: formValues.providerStoreName,
          // address: formValues.address,
          // contactEmail: formValues.contactEmail,
          // contactMobile: formValues.contactMobile,
          // addressProof: formValues.address_proof,
          idProof: formValues.aadhar_proof,
          bankDetails: {
            accHolderName: formValues.accHolderName,
            accNumber: formValues.accNumber,
            IFSC: formValues.IFSC,
            cancelledCheque: formValues.cancelledCheque,
            bankName: formValues.bankName,
            branchName: formValues.branchName,
          },
          aadhar: { aadhar: formValues.aadhar },
          PAN: { PAN: formValues.PAN, proof: formValues.PAN_proof },
          GSTN: { GSTN: formValues.GSTN, proof: formValues.GST_proof },
          // FSSAI: formValues.FSSAI,
        },
      };
      const url = `/api/v1/organizations/signup`;

      const res = await postCall(url, data);
      setFormSubmited(false);
      navigate("/");
      cogoToast.success("Provider onboarded successfully");
    } catch (error) {
      console.log("error.response", error.response);
      cogoToast.error(error.response.data.error);
    }
  };

  // const checkDisabled = () => {
  //   if (user.email == "" || !isEmailValid(user.email)) return true;
  //   if (user.password == "" || !isPhoneNoValid(user.mobile_number)) return true;
  //   if (user.provider_admin_name.trim() == "") return true;
  //   return false;
  // };

  const renderHeading = () => {
    if (step === 1) return "Required Documents";
    if (step == 2) return "Business Details"; // "User Details";
    if (step == 3) return "GST Details";
    if (step == 4) return "Bank Account Details";
    if (step == 5) return "Seller KYC Details";
  };
  const renderSubHeading = () => {
    if (step === 4) return "Bank account should be in the name of registered business name or trade name as per GSTIN.";
    if (step === 5) return "Enter the Business Owner’s Details.";
  }

  const renderFormFields = (fields) => {
    return fields.map((item) => (
      <RenderInput
        item={{
          ...item,
          error: !!errors?.[item.id],
          helperText: errors?.[item.id] || "",
        }}
        state={formValues}
        stateHandler={setFormValues}
      />
    ));
  };

  const renderSteps = () => {
    let uFields = [
      ...userFields,
      {
        id: "password",
        title: "Password",
        placeholder: "Set your password",
        type: "input",
        required: true,
      },
    ];
    if (step === 1) return tncFields();
    if (step == 2) return renderFormFields(uFields);
    if (step == 3) return renderFormFields(kycDetailFields);
    if (step == 4) return renderFormFields(bankDetailFields);
    if (step == 5) return renderFormFields(kycDocumentFields);
  };

  const handleBack = () => {
    if (step === 1) {
      navigate('/');
    } else {
      setStep(step - 1);
    }
  };

  const validate = () => {
    const formErrors = {};
    if (step === 2) {
      formErrors.email =
        formValues.email.trim() === ""
          ? "Email is required"
          : !isEmailValid(formValues.email)
            ? "Please enter a valid email address"
            : "";
      formErrors.mobile =
        formValues.mobile.trim() === ""
          ? "Mobile Number is required"
          : !isPhoneNoValid(formValues.mobile)
            ? "Please enter a valid mobile number"
            : "";
      formErrors.providerStoreName =
        formValues.providerStoreName.trim() === ""
          ? "Business Name is required"
          : "";
      formErrors.password =
        formValues.password.trim() === "" ? "Password is required" : "";
    } else if (step === 3) {
      // formErrors.address =
      //   formValues.address.trim() === ""
      //     ? "Registered Address is required"
      //     : "";
      formErrors.GSTN =
        formValues.GSTN.trim() === ""
          ? "GSTIN Certificate is required"
          : !isValidGSTIN(formValues.GSTN)
            ? "Please enter a valid GSTIN."
            : "";
      formErrors.GST_proof =
        formValues.GST_proof.trim() === ""
          ? "GSTIN Certificate is required"
          : "";
    } else if (step === 4) {
      formErrors.accHolderName =
        formValues.accHolderName.trim() === ""
          ? "Account Holder Name is required"
          : !isNameValid(formValues.accHolderName)
            ? "Please enter a valid account holder name"
            : "";
      formErrors.accNumber =
        formValues.accNumber.trim() === ""
          ? "Account Number is required"
          : !isValidBankAccountNumber(formValues.accNumber)
            ? "Please enter a valid number"
            : "";
      formErrors.bankName =
        formValues.bankName.trim() === "" ? "Bank Name is required" : "";
      formErrors.branchName =
        formValues.branchName.trim() === "" ? "Branch Name is required" : "";
      formErrors.IFSC =
        formValues.IFSC.trim() === ""
          ? "IFSC Code is required"
          : !isValidIFSC(formValues.IFSC)
            ? "Please enter a valid IFSC Code"
            : "";
      formErrors.cancelledCheque =
        formValues.cancelledCheque.trim() === ""
          ? "Bank Passbook / Cancelled Cheque is required"
          : "";
    } else if (step === 5) {
      formErrors.name =
        formValues.name.trim() === ""
          ? "Name is required"
          : !isNameValid(formValues.name)
            ? "Please enter a valid Name"
            : "";
      formErrors.PAN =
        formValues.PAN.trim() === ""
          ? "PAN is required"
          : !isValidPAN(formValues.PAN)
            ? "Please enter a valid PAN number"
            : "";
      // formErrors.PAN_proof =
      //   formValues.PAN_proof.trim() === "" ? "PAN Card Image is required" : "";
      // formErrors.address_proof =
      //   formValues.address_proof.trim() === ""
      //     ? "Address Proof is required"
      //     : "";
      formErrors.aadhar =
        formValues.aadhar.trim() === ""
          ? "Aadhar is required"
          : !isValidAadhar(formValues.aadhar)
            ? "Please enter a valid Aadhar number"
            : "";
      formErrors.aadhar_proof =
        formValues.aadhar_proof.trim() === "" ? "Aadhar Card is required" : "";
      // formErrors.signature_image =
      //   formValues.signature_image.trim() === "" ? "Signature image is required. It will be used only to generate customer invoices." : "";
      // formErrors.captcha =
      //   formValues.captcha.trim() === ""
      //     ? "Captcha is required"
      //     : !validateCaptcha(formValues.captcha)
      //       ? "Captcha does not match"
      //       : "";
    }
    setErrors({
      ...formErrors,
    });
    return !Object.values(formErrors).some((val) => val !== "");
  };

  const handleSubmit = () => {
    if (validate()) {
      step === 5 ? sendInvite() : handleContinue();
    }
  };

  // useEffect(() => {
  //   if (!formSubmitted) return
  //   validate()
  // }, [formValues])

  console.log("formValues====>", formValues);
  return (
    <div
      className="mx-auto !p-5 h-screen min-vh-100 overflow-auto bg-[#f0f0f0]"
      style={{ height: "100%", marginTop: "10px" }}
    >
      <div className="h-full flex fex-row items-center justify-center">
        <div
          className="flex w-full md:w-2/4 bg-white px-4 py-4 rounded-md shadow-xl h-max"
          style={{ boxShadow: "-2px 10px 20px -1px rgba(237, 60, 173, 0.4)" }}
        >
          <div className="m-auto w-10/12 md:w-3/4 h-max">
            <form>
              <p className="text-2xl font-semibold mb-4 text-center">
                {renderHeading()}
              </p>
              <p className="text-2x1 mb-4 text-center">{renderSubHeading()}</p>
              <div>
                {renderSteps()}
                {step === 5 ? (
                  <>
                    {/* <div className="py-1">
                      <LoadCanvasTemplate />
                    </div>
                    <div className="py-1">
                      <RenderInput
                        item={{
                          id: "captcha",
                          // title: "Serviceable Radius/Circle (in Kilometer)",
                          placeholder: "Enter Captcha Value",
                          type: "input",
                          error: errors?.["captcha"] ? true : false,
                          helperText: errors?.["captcha"] || "",
                        }}
                        state={formValues}
                        stateHandler={setFormValues}
                      />
                    </div> */}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="flex mt-6">
                <Button
                  type="button"
                  size="small"
                  style={{ marginRight: 10, backgroundColor: '#1c75bc', color: "#ffff", boxShadow: "-2px 10px 20px -1px rgba(237, 60, 173, 0.4)" }}
                  variant="text"
                  onClick={handleBack}
                  disabled={formSubmitted}
                >
                  Back
                </Button>
                <Button
                  type="button"
                  size="small"
                  style={{ backgroundColor: '#1c75bc', boxShadow: "-2px 10px 20px -1px rgba(237, 60, 173, 0.4)" }}
                  disabled={formSubmitted}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                //  disabled={checkDisabled()}
                >
                  {step == 5 ? "SignUp" : step === 1 ? "I Agree" : "Continue"}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteProvider;
