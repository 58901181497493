import { Button, Select, MenuItem, Box, TextField, InputLabel } from "@mui/material";
import { useState } from "react";
import cogoToast from "cogo-toast";
import { patchCall } from "../../../Api/axios";

const SettlementUpdatesForm = ({settlementInfo, onUpdateSettlementComplete}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSettlementUpdate = async(event) => {
        event.preventDefault();
        setIsSubmitting(true);
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        const updateUrl = `/api/v1/settlements/updateSettlement?settlementId=${settlementInfo._id}`
        await patchCall(updateUrl, formJson)
            .then((res)=>{
                cogoToast.success("Settlement Details Updated Successfully!");
                setIsSubmitting(false);
                onUpdateSettlementComplete();
            })
            .catch((error)=>{
                cogoToast.error("Something went wrong, check console for more info!!!");
                console.error(error);
                setIsSubmitting(false);
            });
    }

  return (
    <Box component="form" onSubmit={handleSettlementUpdate} className="flex flex-col justify-center">
              <Box component="div" className="flex justify-between items-center mt-2">
                <InputLabel htmlFor="receiverReconDate">Receiver Recon Date</InputLabel>
                <TextField id="receiverReconDate" type="date" name="receiverReconDate" defaultValue={(settlementInfo.receiverReconDate)?.split('T')[0]}/>
              </Box>
              <Box component="div" className="flex justify-between items-center mt-2">
                <InputLabel htmlFor="tdsDepositedDate">TDS Deposited Date</InputLabel>
                <TextField id="tdsDepositedDate" type="date" name="tdsDepositedDate" defaultValue={(settlementInfo.tdsDepositedDate)?.split('T')[0]}/>
              </Box>
              <Box component="div" className="flex justify-between items-center mt-2">
                <InputLabel htmlFor="tdsRefNum">TDS Reference Number</InputLabel>
                <TextField id="tdsRefNum" name="tdsRefNum" defaultValue={settlementInfo.tdsRefNum}/>
              </Box>
              <Box component="div" className="flex justify-between items-center mt-2">
                <InputLabel htmlFor="tcsDepositedDate">TCS Deposited Date</InputLabel>
                <TextField id="tcsDepositedDate" type="date" name="tcsDepositedDate" defaultValue={(settlementInfo.tcsDepositedDate)?.split('T')[0]}/>
              </Box>
              <Box component="div" className="flex justify-between items-center mt-2">
                <InputLabel htmlFor="tcsRefNum">TCS Reference Number</InputLabel>
                <TextField id="tcsRefNum" name="tcsRefNum" defaultValue={settlementInfo.tcsRefNum}/>
              </Box>
              <Box component="div" className="flex justify-between items-center mt-2">
                <InputLabel htmlFor="sellerSettlementRefNum">Seller Settlement Reference Number</InputLabel>
                <TextField id="sellerSettlementRefNum" name="sellerSettlementRefNum" defaultValue={settlementInfo.sellerSettlementRefNum}/>
              </Box>
              <Box component="div" className="flex justify-between items-center mt-2">
                <InputLabel htmlFor="lspFees">LSP Fees</InputLabel>
                <TextField id="lspFees" name="lspFees" defaultValue={settlementInfo.lspFees} disabled/>
              </Box>
              <Box component="div" className="flex justify-between items-center mt-2">
                <InputLabel htmlFor="lspSettlementRefNum">LSP Settlement Reference Number</InputLabel>
                <TextField id="lspSettlementRefNum" name="lspSettlementRefNum" defaultValue={settlementInfo.lspSettlementRefNum}/>
              </Box>
              <Box component="div" className="flex justify-between items-center mt-2">
                <InputLabel htmlFor="lspSettlementDate">LSP Settlement Date</InputLabel>
                <TextField id="lspSettlementDate" type="date" name="lspSettlementDate" defaultValue={(settlementInfo.lspSettlementDate)?.split('T')[0]}/>
              </Box>
              <Box component="div" className="flex justify-between items-center mt-2">
              <InputLabel id="settlement-status">Seller Settlement Status</InputLabel>
              <Select
                labelId="settlement-status"
                id="sellerSettlementStatus"
                name="sellerSettlementStatus"
                defaultValue={settlementInfo.sellerSettlementStatus}>
                  <MenuItem value={'yes'}>Yes</MenuItem>
                  <MenuItem value={'no'}>No</MenuItem>
              </Select>
              </Box>
              <Button variant="contained" type="submit" sx={{marginTop:2, fontWeight:700}} disabled={isSubmitting}>{isSubmitting?'Updating...':'Update'}</Button>
            </Box>
  )
}
export default SettlementUpdatesForm