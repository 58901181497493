import React, { useState } from "react";
import { TextField, MenuItem, CircularProgress } from "@mui/material";

const GemstoneFields = ({ gemstone, onChange, onRemove, gemstoneFields }) => {
  const [isLoading, setIsLoading] = useState(false); // State to track loading state

  const handleGemstoneChange = (updatedValue, key) => {
    onChange({ ...gemstone, [key]: updatedValue });
  };

  return (
    <div className="md:w-[24rem] mx-[1rem]">
      {gemstoneFields.map(({ id, title, type, options = [], width = "350px" }) => (
        <div key={id} style={{ marginTop: "1rem" }}>
          <TextField
            label={title}
            value={gemstone[id]}
            onChange={(e) => handleGemstoneChange(e.target.value, id)}
            variant="outlined"
            size="small"
            fullWidth
            select={type === "select"}
            type={type === "number" ? "number" : "text"}
            InputLabelProps={{ shrink: true }}
          >
            {options.map(({ key, value }) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </TextField>
          {isLoading && (
            <div style={{ margin: "10px" }}>
              <CircularProgress size={24} />
            </div>
          )}
          {/* <div style={{ margin: "10px" }}>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={onRemove}
            >
              <img
                alt="Remove"
                src="https://d30y9cdsu7xlg0.cloudfront.net/png/53504-200.png"
                style={{
                  cursor: "pointer",
                  float: "right",
                  marginTop: "7px",
                  width: "17px",
                }}
              />
            </button>
          </div> */}
        </div>
      ))}
    </div>
  );
};

export default GemstoneFields;
