//TODO: This Inventory.jsx corresponds to Provider/Seller View, fix the file name, also for InventoryTable.
import { useEffect, useState} from "react";
import InventoryTable from "../Inventory/InventoryTable";
import Button from "../../Shared/Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { getCall } from "../../../Api/axios";
import useCancellablePromise from "../../../Api/cancelRequest";
import { isObjEmpty } from "../../../utils/validations";
import { PRODUCT_CATEGORY } from "../../../utils/constants";
import { useTheme } from "@mui/material/styles";
import FilterComponent from "../../Shared/FilterComponent";
import { CircularProgress } from "@mui/material";
import cogoToast from "cogo-toast";

const filterFields = [
  {
    id: "name",
    title: "",
    placeholder: "Search by Product Name",
    type: "input",
    variant: "standard",
  },
  // {
  //   id: "category",
  //   title: "",
  //   placeholder: "Please Select Product Category",
  //   options: Object.entries(PRODUCT_CATEGORY).map(([key, value]) => {
  //     return { key: value, value: key };
  //   }),
  //   type: "select",
  //   variant: "standard",
  //   disableClearable: true,
  // },
  {
    id: "QCStatus",
    title: "",
    placeholder: "QC Status",
    options: [
      {key:"In Progress",value:"In Progress"},
      {key:"Approved",value:"Approved"},
      {key:"Rejected",value:"Rejected"},
    ],
    
    type: "select",
    variant: "standard",
    // disableClearable: true,
    multiple: true
  },
  // {
  //   id: "stock",
  //   title: "Out of Stock",
  //   placeholder: "Please Select Product Category",
  //   type: "switch",
  //   containerClasses: "flex items-center",
  //   styles: {
  //     marginLeft: 2,
  //   },
  // },
  {
    id: "published",
    title: "",
    placeholder: "Published",
    type: "select",
    options: [
      {key:"Yes", value:"true"},
      {key:"No", value:"false"}
    ],
    variant: "standard",
    // disableClearable: true,
    multiple: true
  },
];

const columns = [
  {
    id: "productSubcategory2",
    label: "Product Type",
    minWidth: 120,
    // format: (value) => PRODUCT_CATEGORY[value] || value,
  },
  { id: "productName", label: "Product Name", minWidth: 100 },
  {id:"productCode",label:"Product Number",minWidth:100},
  {
    id: "createdAt",
    label: "Created At",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "quantity",
    label: "Quantity",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-US"),
  },
 
  // {
  //   id: "isCancellable",
  //   label: "Cancellable",
  //   boolean: true,
  //   minWidth: 100,
  // },
  // {
  //   id: "isReturnable",
  //   label: "Returnable",
  //   boolean: true,
  //   minWidth: 100,
  // },
  {
    id: "QCStatus",
    label: "QC Status",
    minWidth: 100,
  },
  {
    id: "QCDesc",
    label: "QC Comments",
    minWidth: 150,
  },

  {
    id: "published",
    label: "Published",
  },
];

export default function Inventory() {
  const theme = useTheme();
  const [page, setPage] = useState(() => {
    const savedPage = sessionStorage.getItem("inventorytablepage");
    return savedPage !== null ? parseInt(savedPage, 10) : 0
  });
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    const savedRowsPerPage = sessionStorage.getItem("inventorytablerowsperpage");
    return savedRowsPerPage !== null ? parseInt(savedRowsPerPage, 10) : 25
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const navigate = useNavigate();
  const { cancellablePromise } = useCancellablePromise();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [filters, setFilters] = useState({
    name: "",
    category: "",
    stock: false,
    QCStatus:[],
    published:[]
  });

  const getProducts = async () => {
    try {
      const res = await cancellablePromise(getCall(`/api/v1/SellerProducts?limit=${rowsPerPage}&offset=${page}`));
      setProducts(res.data);
      setTotalRecords(res.count);
      setLoading(false);
    } catch (error) {
      cogoToast.error("Something went wrong!");
      setLoading(false);
    }
  };

  const getOrgDetails = async (org_id) => {
    const url = `/api/v1/organizations/${org_id}/storeDetails`;
    const res = await getCall(url);
    return res;
  };

  const getUser = async (id) => {
    const url = `/api/v1/users/${id}`;
    const res = await getCall(url);
    return res[0];
  };

  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    getUser(user_id).then((u) => {
      // roles - Organization Admin, Super Admin
      if (u.isSystemGeneratedPassword) navigate("/initial-steps");
      else {
        if (u.role.name == "Organization Admin") {
          getOrgDetails(u.organization).then((org) => {
            if (isObjEmpty(org.storeDetails)) navigate("/initial-steps");
          });
        } else navigate("/product-listings");
      }
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    getProducts();
  }, [page, rowsPerPage]);

  const handleRefresh = (data) => {
    getProducts();
  };

  const onReset = () => {
    setFilters({ name: "", category: null, stock: false, QCStatus: [], published: [] });
    getProducts();
  };

  const onFilter = async () => {
    const filterParams = [];
    if (filters.name.trim() !== "") {
      filterParams.push(`name=${encodeURIComponent(filters.name)}`);
    }

    if (filters.category != undefined && filters.category !== "") {
      filterParams.push(`category=${encodeURIComponent(filters.category)}`);
    }

    // if (!filters.stock) {
    //   filterParams.push(`stock=inStock`);
    // } else {
    //   filterParams.push(`stock=outOfStock`);
    // }

    if(filters.QCStatus.length>0){
      console.log('status', filters.QCStatus)
      filterParams.push(`QCStatus=${JSON.stringify(filters.QCStatus.map(item=>item.value))}`);
    }

    if(filters.published.length>0){
      filterParams.push(`published=${JSON.stringify(filters.published.map(item=>item.value))}`);
    }

    const queryString = filterParams.join("&");
    const url = `/api/v1/SellerProducts?${queryString}`;

    const res = await cancellablePromise(getCall(url));
    setProducts(res.data);
    setTotalRecords(res.count);
  };

  const handlePageChange = (val) => {
    setPage(val);
    setLoading(true);
    sessionStorage.setItem("inventorytablepage", val);
  }

  const handleRowsPerPageChange = (val) => {
    setRowsPerPage(val);
    sessionStorage.setItem("inventorytablerowsperpage", val);
  }

  const loadingView = (
    <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "#fff",
      }}
    >
      <CircularProgress />
    </div>
  );

  return (
    <>
      { loading ? loadingView : ( <div className="container mx-auto my-8 px-2 md:px-8">
        <div className="mb-4 flex flex-row justify-between items-center">
          <label style={{ color: theme.palette.text.primary }} className="font-semibold text-2xl">
            Inventory
          </label>
          <div className="flex">
            {/* <div style={{ marginRight: 15 }}>
              <Button
                variant="contained"
                icon={<AddIcon />}
                title="Bulk upload"
                onClick={() => navigate("/application/bulk-upload")}
              />
            </div> */}
            <Button
              variant="contained"
              icon={<AddIcon />}
              className=""
              title="ADD PRODUCT"
              onClick={() => navigate("/application/add-products")}
            />
          </div>
        </div>
        <FilterComponent
          fields={filterFields}
          state={filters}
          stateHandler={setFilters}
          onReset={onReset}
          onFilter={onFilter}
        />
        <InventoryTable
          columns={columns}
          data={products}
          onRefresh={handleRefresh}
          totalRecords={totalRecords}
          page={page}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </div> )}
    </>
  );
}