import React from "react";
import Button from "./Button";
import RenderInput from "../../utils/RenderInput";
import { useTheme } from "@mui/material/styles";
import { FormControl, Autocomplete, TextField } from "@mui/material";

const FilterComponent = (props) => {
  const { fields = [], state, stateHandler, onFilter, onReset } = props;
  const theme = useTheme();

  //   console.log("State", state);

  return (
    <div className=" mb-4 items-center mt-8">
      <div className="mr-12">
        <p className=" text-xl font-semibold" style={{ color: theme.palette.text.primary }}>
          Filters
        </p>
      </div>
      <div className="flex">
        <div className="flex items-end flex-wrap">
          {fields.map((item) => {
            if(item?.type === "select") {
              return <FormControl className="!w-60 !py-1 !mr-4" key={item.id}>
              <Autocomplete
                multiple
                options={item?.options}
                size="small"
                getOptionLabel={(option) => option.key}
                value={
                  item.options?.length>0 ? state[item.id] : null
                }
                onChange={(e, value)=>stateHandler({...state, [item.id]: value})}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={item.placeholder}
                    variant="standard"
                    error={false}
                  />
                )}
              />
            </FormControl>
            }
            return (
              <RenderInput
                key={item.id}
                item={{ ...item }}
                state={state}
                stateHandler={stateHandler}
                inputStyles={{ width: 240 }}
                containerClasses={"py-1 flex flex-col mr-4"}
              />
            );
          })}
        </div>
        <div className="flex items-end justify-center">
          <div className="flex flex-col gap-4 sm:flex-row">
          <Button title="Reset" variant="contained" sx={{ fontSize: 12 }} onClick={onReset} />
          <Button title="Filter" variant="contained" sx={{ fontSize: 12 }} onClick={onFilter} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;
