import { useEffect, useState } from "react";
import cogoToast from "cogo-toast";
import moment from "moment";
import MyButton from "../../Shared/Button";
import { useLocation, useNavigate } from "react-router-dom";
import useCancellablePromise from "../../../Api/cancelRequest";
import { getCall, postCall, putCall } from "../../../Api/axios";
import useForm from "../../../hooks/useForm";
import {
  PRODUCT_SUBCATEGORY,
  FIELD_NOT_ALLOWED_BASED_ON_PROTOCOL_KEY,
  MAX_STRING_LENGTH,
  MAX_STRING_LENGTH_50,
  MAX_STRING_LENGTH_14,
  MAX_STRING_LENGTH_3,
  MAX_STRING_LENGTH_6,
  MAX_STRING_LENGTH_10,
  MAX_STRING_LENGTH_13,
  MAX_STRING_LENGTH_8,
  MAX_STRING_LENGTH_12,
} from "../../../utils/constants";
import { isAmountValid, isNumberOnly } from "../../../utils/validations";
import {
  allProductFieldDetails,
  categoryFields,
  productDetailsFields,
  variationCommonFields,
  UOMVariationFields,
} from "./product-fields-modified";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AddVariants from "./AddVariants";
import { allProperties } from "./categoryProperties";
import AddProductInfo from "./AddProductInfo";
import { getFormErrors } from "./utils";
import VitalForm from "./VitalForm";
import { v4 as uuidv4 } from "uuid";
import CustomizationRenderer from "./CustomizationRenderer";
const UpdateGenricProduct = ({
  state,
  category,
  subCategory,
  categoryForm,
  selectedVariantNames,
  variants,
  attributes,
  variationOn,
  jewelleryType,
  jewelleryCategory,
}) => {
  const initialValues = {
    productCode: "",
    productName: "",
    HSNCode: "7113",
    GST_Percentage: 3,
    quantity: 1,
    QCStatus: "InProgress",
    QCDesc: "In Progress",
    huidNumber: "",

    Finishing: "",

    weight: "",
    wastageOnGold: "",
    dispatchTime: "",
    net_weight: "",
    diamonds_weight: "",
    diamondColour: "",
    diamondClarity: "",
    Purity: "",

    returnWindow: "",
    totalSellingPrice: "",

    diamondCostPerCt: "",
    makingCharges: "",

    description: "",

    isReturnable: "false",
    isCancellable: "false",

    images: [],
    certifications: [],
    manufacturerOrPackerName: "",
    manufacturerOrPackerAddress: "",

    fulfillmentOption: "",
    countryOfOrigin: "",
    gemstones: [],
    gemstonePrice: "",
  };

  const productInfoForm = useForm({
    ...initialValues,
  });
  const navigate = useNavigate();
  const [vitalFormErrors, setVitalFormErrors] = useState({});
  const [vitalFields, setVitalFields] = useState([]);
  const { formValues, setFormValues } = productInfoForm;
  const [focusedField, setFocusedField] = useState("");
  const [allFields, setAllFields] = useState(allProductFieldDetails);
  const [productInfoFields, setProductInfoFields] = useState({}); // State to store product information fields
  const [vitalForm, setVitalForm] = useState({}); // State to store vital form data
  const getProductInfoFields = () => {
    let product_info_fields = [...productDetailsFields];
    let p_category = state?.productId ? state?.productCategory : category;
    let p_sub_category = state?.productId
      ? state?.productSubCategory
      : subCategory;
    let protocolKey = PRODUCT_SUBCATEGORY[p_category]?.filter(
      (sub_category) => sub_category.value === p_sub_category
    )[0].protocolKey;

    if (protocolKey && protocolKey !== "") {
      let fields_to_remove =
        FIELD_NOT_ALLOWED_BASED_ON_PROTOCOL_KEY[protocolKey];
      product_info_fields = product_info_fields.filter(
        (field) => !fields_to_remove.includes(field)
      );
    }

    if (category !== "Grocery" && category !== "F&B") {
      product_info_fields = product_info_fields.filter(
        (field) => field !== "vegNonVeg"
      );
    }
    if (jewelleryType !== "diamond") {
      product_info_fields = product_info_fields.filter(
        (field) =>
          ![
            "diamondColour",
            "diamonds_weight",
            "diamondClarity",
            "certifications",
            "diamondCostPerCt",
            "makingCharges",
          ].includes(field)
      );
    }

    if (!variationOn || variationOn === "none") {
      return [
        ...product_info_fields,
        ...UOMVariationFields,
        ...variationCommonFields,
      ];
    } else if (variationOn === "attributes") {
      return [...product_info_fields, ...UOMVariationFields];
    } else {
      return product_info_fields;
    }
  };
  const calculateGemstonePrice = (updatedValues) => {
    let updatedGemstonePrice = 0;
    updatedGemstonePrice = updatedValues.gemstones.reduce((total, gemstone) => {
      return (
        total +
        parseInt(gemstone.gemstoneCostPerStone) *
          parseInt(gemstone.gemstoneCount)
      );
    }, 0);
    return updatedGemstonePrice;
  };
  const calculateTotalSellingPrice = (updatedValues) => {
    let updatedTotalSellingPrice = 0;
    const goldRate = 6433;

    // Ensure that the values are parsed as floats or default to 0 if not available
    const diamondCostPerCt = parseFloat(updatedValues.diamondCostPerCt) || 0;
    const makingCharges = parseFloat(updatedValues.makingCharges) || 0;
    const gemstonePrice = parseFloat(updatedValues.gemstonePrice) || 0;
    const net_weight = parseFloat(updatedValues.net_weight) || 0;

    // Calculate the total selling price
    updatedTotalSellingPrice =
      (updatedValues.net_weight *
        (1 + updatedValues.wastageOnGold / 100) *
        goldRate +
        diamondCostPerCt +
        (makingCharges * net_weight) +
        gemstonePrice) *
      (1 + parseFloat(updatedValues.GST_Percentage) / 100);

    return updatedTotalSellingPrice;
  };

  setInterval(() => {
    setFormValues((prevValues) => ({
      ...prevValues,
      gemstonePrice: calculateGemstonePrice(prevValues).toFixed(2),
      totalSellingPrice: calculateTotalSellingPrice(prevValues).toFixed(2),
    }));
  }, 2000);

  useEffect(() => {
    setProductInfoFields(getProductInfoFields());
    const getProduct = async () => {
      try {
        const resp = await getCall(`/api/v1/SellerProducts/${state.productId}`);
        const updatedCommonDetails = { ...resp.commonDetails };
        updatedCommonDetails.uploaded_urls =
          resp?.commonDetails.images?.map((i) => constructProofUrl(i?.url)) ||
          [];
        updatedCommonDetails.images =
          resp?.commonDetails.images?.map((i) => constructProofUrl(i?.path)) ||
          [];
        updatedCommonDetails.certifications =
          resp?.commonDetails.images?.map((i) => constructProofUrl(i?.path)) ||
          [];
        updatedCommonDetails.isCancellable = resp.commonDetails.isCancellable
          ? "true"
          : "false";
        updatedCommonDetails.isReturnable = resp.commonDetails.isReturnable
          ? "true"
          : "false";
        updatedCommonDetails.isVegetarian = resp.commonDetails.isVegetarian
          ? "true"
          : "false";
        updatedCommonDetails.availableOnCod = resp.commonDetails.availableOnCod
          ? "true"
          : "false";

        const duration = moment.duration(resp.returnWindow);
        const hours = duration.asHours();
        updatedCommonDetails.returnWindow = String(hours);

        const updatedAttributes = { ...resp.commonAttributesValues };
        if (updatedAttributes["size_chart"]) {
          updatedAttributes["size_chart"] = updatedAttributes["size_chart"].url;
        }
        setFormValues(updatedCommonDetails);
        setProductInfoFields(updatedCommonDetails);
        setVitalForm(updatedAttributes);
        setVitalFields({ ...resp.commonAttributesValues });
        // Other state updates as needed
      } catch (error) {
        cogoToast.error("Something went wrong!");
        console.log(error);
      }
    };

    getProduct(); // Call the getProduct function when the component mounts
  }, [state.productId]); // Add state.productId as a dependency to re-fetch data when it changes
  const renderProductInfoFields = () => {
    return (
      <AddProductInfo
        allFields={allFields}
        fields={productInfoFields}
        category={category}
        subCategory={subCategory}
        state={state}
        form={productInfoForm}
        setFocusedField={setFocusedField}
      />
    );
  };
  const renderProductVitalFields = () => {
    return (
      <VitalForm
        fields={vitalFields}
        formData={vitalForm}
        onFormUpdate={setVitalForm}
        vitalFormErrors={vitalFormErrors}
      />
    );
  };
  const updateProduct = async () => {
    // id will be dynamic after schema changes
    try {
      let product_data = Object.assign({}, formValues);
      let vital_data = Object.assign({}, vitalForm);
      const subCatList = PRODUCT_SUBCATEGORY[formValues?.productCategory];
      const selectedSubCatObject = subCatList.find(
        (subitem) => subitem.value === formValues?.productSubcategory1
      );
      if (selectedSubCatObject && selectedSubCatObject.protocolKey) {
        const hiddenFields =
          FIELD_NOT_ALLOWED_BASED_ON_PROTOCOL_KEY[
            selectedSubCatObject.protocolKey
          ];
        hiddenFields.forEach((field) => {
          delete product_data[field];
        });
      } else {
      }

      // Create a duration object with the hours you want to convert
      const duration = moment.duration(
        parseInt(product_data.returnWindow),
        "hours"
      );

      // Format the duration in ISO 8601 format
      const iso8601 = duration.toISOString();
      product_data.returnWindow = iso8601;

      let fields_to_remove = [
        "__v",
        // "organization",
        "createdAt",
        "updatedAt",
        "published",
        "uploaded_urls",
        "createdBy",
        "_id",
        "variantGroup",
      ];

      fields_to_remove.forEach((field) => {
        delete product_data[field];
      });

      delete vital_data["tempURL"];

      let data = {
        commonDetails: product_data,
        commonAttributesValues: vital_data,
        // customizationDetails: {
        //   customizationGroups,
        //   customizations,
        // },
      };

      await putCall(`/api/v1/SellerProducts/${state.productId}`, data);
      cogoToast.success("Product updated successfully!");
      navigate("/application/inventory");
    } catch (error) {
      cogoToast.error("Something went wrong!");
      console.log(error);
    }
  };
  
  function constructProofUrl(originalUrl) {
    if (!originalUrl) {
      return undefined;
    }
    console.log(originalUrl);
    let myBucket = process.env.REACT_APP_S3_BUCKET;
    let regionString = '-' + process.env.REACT_APP_S3_REGION;
    const baseUrl = `https://${myBucket}.s3${regionString}.amazonaws.com`;
    const replacementString = "/public-assets/";

    let modifiedImagePath = originalUrl.replace(new RegExp(`^${baseUrl}/`), "");
    console.log("Modified Image Path", modifiedImagePath);

    let newlymodifiedImagePath = modifiedImagePath.replace(
      new RegExp(replacementString),
      "/"
    );
    console.log("WITHOUT PUBLIC ASSETS", newlymodifiedImagePath);

    const modifiedUrl = newlymodifiedImagePath
      .replace(/\+/g, "%2B")
      .replace(/ /g, "+");

    const newUrl = `${baseUrl}/${modifiedUrl}`;
    console.log("CONSTRUCT FUNCTION DONE WORKING");
    console.log(newUrl);
    return newUrl;
  }
  const handleSubmit = () => {
    
      updateProduct();
  };
    
  return (
    <form>
      <Box>
        <TabContext>
          <TabPanel>
            <div className="mt-2">{renderProductInfoFields()}</div>
          </TabPanel>
          <TabPanel>
            <div className="mt-2">{renderProductVitalFields()}</div>
          </TabPanel>
        </TabContext>
      </Box>
      <div className="flex flex-row justify-center py-2 sm:pt-5 md:!mt-10">
        <MyButton
          type="button"
          title={state?.productId ? "Update Product" : "ADD PRODUCT"}
          variant="contained"
          className="!ml-5"
          onClick={handleSubmit}
        />
      </div>
    </form>
  );
};
export default UpdateGenricProduct;
