//TODO: This InventoryTable.jsx corresponds to Provider/Seller View, fix the file name, also for Inventory.
import { useState, Fragment,useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CachedIcon from "@mui/icons-material/Cached";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button"; 
import Menu from "@mui/material/Menu";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { getCall, postCall, putCall } from "../../../Api/axios";
import cogoToast from "cogo-toast";
import Tooltip from "@material-ui/core/Tooltip";
import { useTheme } from "@mui/material/styles";
import { convertDateInStandardFormat } from "../../../utils/formatting/date";

const StyledTableCell = styled(TableCell)({
  "&.MuiTableCell-root": {
    fontWeight: "bold",
  },
});
const StyledMoreVertIcon = styled(MoreVertIcon)(({theme})=>({
  color : theme.palette.tertiary.main
}));

export default function InventoryTable(props) {
  const {
    page,
    rowsPerPage,
    totalRecords,
    handlePageChange,
    handleRowsPerPageChange,
    onRefresh,
  } = props;
  const theme = useTheme();

  const onPageChange = (event, newPage) => {
    handlePageChange(newPage);
  };

  const onRowsPerPageChange = (event) => {
    handleRowsPerPageChange(parseInt(event.target.value, 10));
    handlePageChange(0);
  };

  const ThreeDotsMenu = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);


    const handleClick = (e) => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const { row } = props;
    // const [jewelleryType, setJewelleryType] = useState("");
    // const [jewelleryCategory, setJewelleryCategory] = useState("");
  
    // Effect to fetch data for jewelleryType and jewelleryCategory when row updates
    // useEffect(() => {
    //   if (row) {
    //     getCall(`/api/v1/products/GetDataForQC/${row._id}`)
    //       .then((resp) => {
    //         const type = resp?.commonDetails?.productSubcategory2 || "";
    //         const category = resp?.commonDetails?.productSubcategory3 || "";
    //         setJewelleryType(type);
    //         setJewelleryCategory(category);
    //       })
    //       .catch((error) => {
    //         console.error(error);
    //       });
    //   }
    // }, [row]);
    // console.log("Jewellery type for ",row._id, " is ",jewelleryType);
    // console.log("Jewellery Category for ",row._id, " is ",jewelleryCategory);
    

    const handlePublishState = (product_id, published) => {
      const url = `/api/v1/products/${product_id}/publish`;
      putCall(url, { published: !published })
        .then((resp) => {
          cogoToast.success("Product state updated successfully");
          onRefresh();
        })
        .catch((error) => {
          console.log(error);

        });
    };

    const handleOutOfStock = (product_id) => {
      const url = `/api/v1/products/${product_id}/outOfStock`;
      putCall(url)
        .then((resp) => {
          cogoToast.success("Product removed from stock successfully");
          onRefresh();
        })
        .catch((error) => {
          console.error(error);
        });
    };

    return (
      <Fragment>
        <Tooltip title="Action">
          <Button onClick={handleClick}>
            <StyledMoreVertIcon/>
          </Button>
        </Tooltip>
        <Menu
          id="card-actions-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Link
            to="/application/add-products"
            state={{
              productId: row._id,
              productCategory: row.productCategory,
              productSubCategory: row.productSubcategory1,
              jewelleryType: row.productSubcategory2 || "",
              jewelleryCategory: row.productSubcategory3 || "",
            }}
          >
            <MenuItem>Edit</MenuItem>
          </Link>

          <MenuItem onClick={() => handlePublishState(row?._id, row?.published)}>
            {row?.published ? "Unpublish" : "Publish"}
          </MenuItem>
          {row?.quantity > 0 && <MenuItem onClick={() => handleOutOfStock(row?._id)}>
            Out Of Stock
          </MenuItem>}
        </Menu>
      </Fragment>
    );
  };

  const renderCellContent = (column, value) => {
    if (typeof value == "boolean") {
      return (
        <div>
          <span className="ml-2">
            {value === false || value === null ? "No" : "Yes"}
          </span>
        </div>
      );
    } else {
      return column.format ? column.format(value) : value;
    }
  };

  props.columns.map((column)=>column.align="center")
  
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {props.columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                  }}
                >
                  {column.label}
                </StyledTableCell>
              ))}

              <StyledTableCell
                style={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }}
              >
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {props.columns.map((column) => {
                    let value =
                    (row[column.id] === undefined || row[column.id] === "") ? " - " : row[column.id];
                      if(column.id==="createdAt")
                        value = convertDateInStandardFormat(row[column.id])
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {renderCellContent(column, value)}
                      </TableCell>
                    );
                  })}
                  <TableCell component="th" scope="row" align="center">
                    <ThreeDotsMenu row={row} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Paper>
  );
}
