import { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  MenuItem,
  Button,
  IconButton,
  Tooltip,
  Popover,
  FormControl,
  InputLabel,
  Select,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { postCall } from "../../../Api/axios.js";
import cogoToast from "cogo-toast";
import { EditOutlined } from "@mui/icons-material";
import { RETURN_REJECT_REASONS } from "./return-reject-reasons.js";
import { convertDateInStandardFormat } from "../../../utils/formatting/date.js";
import { useTheme } from "@mui/material/styles";

const RETURN_ORDER_STATUS = {
  Return_Initiated: "Return Initiated",
  RTO_Initiated :"RTO Initiated",
  RTO_Delivered :"RTO Delivered",
  "RTO-Initiated":"RTO Initiated",
  "RTO-Delivered":"RTO Delivered",
  Return_Approved: "Return Approved",
  Return_Picked: "Return Picked",
  Return_Pick_Failed: "Return Pickup Failed",
  Return_Failed: "Return Pickup Failed",
  Return_Delivered: "Return Delivered",
  Return_Rejected: "Return_Rejected",
  Liquidated: "Liquidated",
  Reject: "Rejected",
  Rejected: "Rejected",
  Approved: "Approved",
  Track: "Track", 
  Yes: "Yes",
  No: "No",
};

const StyledTableCell = styled(TableCell)({
  "&.MuiTableCell-root": {
    fontWeight: "bold",
  },
});

const ActionMenu = ({ row, handleRefresh }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const [reason, setReason] = useState(null);
  // STATES
  const [inlineError, setInlineError] = useState({
    selected_status_error: "",
    reason_error: "",
  });

  const handleClick = (e) => {
    setOrderStatus(null);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setOrderStatus(null);
    setAnchorEl(null);
  };

  // use this function to check if any order is selected
  function checkIsOrderStatus() {
    if (!orderStatus) {
      setInlineError((error) => ({
        ...error,
        selected_status_error: "Please select status",
      }));
      return false;
    }
    return true;
  }

  // use this function to check if any reason is selected
  function checkReason() {
    if (!reason) {
      setInlineError((error) => ({
        ...error,
        reason_error: "Please select reason",
      }));
      return false;
    }
    return true;
  }

  const updateReturnState = () => {
    const url = `/api/v1/orders/${row.orderId}/item/return`;
    let data = {
      id: row._id,
      state: orderStatus,
    };
    if (orderStatus === RETURN_ORDER_STATUS.Reject) {
      data.reason = reason;
    } else {
    }
    postCall(url, data)
      .then((resp) => {
        cogoToast.success("Status updated successfully");
        handleClose();
        handleRefresh();
      })
      .catch((error) => {
        console.log(error);
        cogoToast.error(error.response?.data?.error || error.message);
      });
  };

  return (
    <>
      <Tooltip title="Update status">
        <IconButton color="tertiary">
          <EditOutlined onClick={handleClick} />
        </IconButton>
      </Tooltip>
      <Popover
        id="edit-order-status"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ width: "400px", p: 2 }}>
          <Stack direction="column" spacing={2}>
            {row.state === "Return_Initiated" ||
            row.state.code === "Return_Initiated" ? (
              <>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={orderStatus}
                    label="Select Status"
                    onChange={(e) => {
                      setInlineError((error) => ({
                        ...error,
                        selected_status_error: "",
                      }));
                      setOrderStatus(e.target.value);
                    }}
                  >
                    <MenuItem value={RETURN_ORDER_STATUS.Approved}>
                      Approve
                    </MenuItem>

                    <MenuItem value={RETURN_ORDER_STATUS.Reject}>
                      Reject
                    </MenuItem>
                  </Select>
                  {inlineError.selected_status_error && (
                    <Typography
                      color="error"
                      variant="subtitle2"
                      style={{ marginLeft: "5px" }}
                    >
                      {inlineError.selected_status_error}
                    </Typography>
                  )}
                </FormControl>
              </>
            ) : row.state === "Return_Delivered" ? (
              <>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Have you received the product in good condition?
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={orderStatus}
                    label="Select Status"
                    onChange={(e) => {
                      setInlineError((error) => ({
                        ...error,
                        selected_status_error: "",
                      }));
                      setOrderStatus(e.target.value);
                    }}
                  >
                    <MenuItem value={RETURN_ORDER_STATUS.Yes}>Yes</MenuItem>
                    <MenuItem value={RETURN_ORDER_STATUS.No}>No</MenuItem>
                  </Select>
                  {inlineError.selected_status_error && (
                    <Typography
                      color="error"
                      variant="subtitle2"
                      style={{ marginLeft: "5px" }}
                    >
                      {inlineError.selected_status_error}
                    </Typography>
                  )}
                </FormControl>
              </>
            )  : row.state ==="RTO-delivered" ?(null) 
            :(
              <>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Track Fulfillment
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={orderStatus}
                    label="Select Status"
                    onChange={(e) => {
                      setInlineError((error) => ({
                        ...error,
                        selected_status_error: "",
                      }));
                      setOrderStatus(e.target.value);
                    }}
                  >
                    <MenuItem value={RETURN_ORDER_STATUS.Track}>Track</MenuItem>
                  </Select>
                  {inlineError.selected_status_error && (
                    <Typography
                      color="error"
                      variant="subtitle2"
                      style={{ marginLeft: "5px" }}
                    >
                      {inlineError.selected_status_error}
                    </Typography>
                  )}
                </FormControl>
              </>
            )}

            {orderStatus === RETURN_ORDER_STATUS.Reject && (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Reason
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={reason}
                  label="Select Status"
                  onChange={(e) => {
                    setInlineError((error) => ({
                      ...error,
                      reason_error: "",
                    }));
                    setReason(e.target.value);
                  }}
                >
                  {RETURN_REJECT_REASONS.map((reason, reasonIndex) => (
                    <MenuItem
                      key={`reason-${reasonIndex}`}
                      value={reason.key}
                      style={{ maxWidth: "500px" }}
                    >
                      {reason.value}
                    </MenuItem>
                  ))}
                </Select>
                {inlineError.reason_error && (
                  <Typography
                    color="error"
                    variant="subtitle2"
                    style={{ marginLeft: "5px" }}
                  >
                    {inlineError.reason_error}
                  </Typography>
                )}
              </FormControl>
            )}
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <Button size="small" variant="outlined" color="tertiary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  let allCheckPassed = true;
                  if (orderStatus === RETURN_ORDER_STATUS.Reject) {
                    allCheckPassed = [
                      checkIsOrderStatus(),
                      checkReason(),
                    ].every(Boolean);
                  } else {
                    allCheckPassed = [checkIsOrderStatus()].every(Boolean);
                  }
                  if (!allCheckPassed) return;

                  updateReturnState();
                }}
              >
                Update
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Popover>
    </>
  );
};

export default function InventoryTable(props) {
  const {
    page,
    rowsPerPage,
    totalRecords,
    handlePageChange,
    handleRowsPerPageChange,
    handleRefresh,
  } = props;
  const theme = useTheme();

  const onPageChange = (event, newPage) => {
    handlePageChange(newPage);
  };

  const onRowsPerPageChange = (event) => {
    handleRowsPerPageChange(parseInt(event.target.value, 10));
    handlePageChange(0);
  };

  const renderCellContent = (column, row) => {
    if (typeof row[column.id] === "boolean") {
      return (
        <div>
          <span className="ml-2">
            {row[column.id] === false || row[column.id] === null ? "No" : "Yes"}
          </span>
        </div>
      );
    } else {
      // return column.format ? column.format(value) : value;
      switch(column.id){
        case "provider_name":
          return row.provider_name;
        
        case "orderId":
          return row.orderId;

        case "item_name":
          return row.item.productName;

        case "qty":
          return row.qty;

        case "item_code":
          return row.item.productCode;

        case "createdAt":
          return convertDateInStandardFormat(row.createdAt);

        case "updatedAt":
          return convertDateInStandardFormat(row.updatedAt);

        case "reason":
          return row.reason;
      }
    }
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {props.columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                  }}
                  className="font-medium"
                >
                  {column.label}
                </StyledTableCell>
              ))}
              <StyledTableCell
                key="refund-header"
                className="font-medium"
                align="center"
                style={{
                  minWidth: 180,
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }}
              >
                Refund Amount
              </StyledTableCell>
              <StyledTableCell
                key="action-header"
                className="font-medium"
                align="center"
                style={{
                  minWidth: 180,
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }}
              >
                Actions
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row, index) => {
              return (
                <TableRow hover tabIndex={-1} key={index}>
                  {props.columns.map((column, idx) => {
                    {/* const value = row[column.id]; */}
                    console.log("Here is the row", row);
                    {/* console.log("Here is the value", value); */}
                    console.log("Here is the column", column);
                    if (column.id === "state") {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {RETURN_ORDER_STATUS[row[column.id]]}
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {renderCellContent(column, row)}
                        </TableCell>
                      );
                    }
                  })}
                  <TableCell align="center" component="th" scope="row">
                    {row.sellerPrice !== 0 && row.sellerPrice !== null
                      ? `Rs. ${row.sellerPrice}`
                      : "NA"}
                  </TableCell>

                  <TableCell align="center" component="th" scope="row">
                    {row.sellerPrice === 0 || row.sellerPrice === null ? (
                      <ActionMenu row={row} handleRefresh={handleRefresh} />
                    ) : row.sellerPrice === "unsatisfied" ? (
                      <> Incorrect Product Returned</>
                    ) : (
                      <></>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Paper>
  );
}
