import OndcRoutes from "./Router/Router";
import {ThemeProvider} from "@mui/material/styles";
import "./Api/firebase-init";
import theme from "./theme";
import { ScrollTop } from "./Components/Shared/ScrollTop";

function App() {
  return (
    <ThemeProvider theme={theme}>
    <div className='App'>
      <OndcRoutes />
      <ScrollTop/>
    </div>
    </ThemeProvider>
  );
}

export default App;
